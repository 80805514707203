const translationEN = {
  locale: "en",
  ctaContact: "Start now",
  mainTitle1: "Transform",
  mainTitle2: "your way of selling online with Alive.",
  mainSubtitle:
    "Connect with your customers and sell live, without needing an app!",
  demo: "schedule a demo",
  featuresTitle: "The best experience",
  featuresSubtitles: [
    "No App",
    "Real-time interactions",
    "Dynamic Buttons",
    "Integrated Process",
    "Personalization",
    "Support",
  ],
  featuresText: [
    "just access a link from your cell phone or computer.",
    "to chat with the seller or other customers.",
    "that show the products and take them straight to the shopping cart.",
    "to your e-commerce: just one click to complete the purchase.",
    "from the platform to keep your brand identity.",
    "and technical support throughout the process.",
  ],
  clientsTitle: "Clients",
  clientsText: "Brands of all differents sizes and markets use Alive.",
  seeAllLives: "See all recordings",
  seeAllLivesMobile: "all recordings",
  liveHausText:
    "Live Haus is a set of services that offers everything you need in one place.",
  liveHausItensTitles: ["Technology", "Production", "Activation"],
  liveHausItensTexts: [
    "Alive platform for interactive lives, with direct integration to e-commerce.",
    "Event planning, script, influencer selection and live interactions.",
    "Activation planning, with online ads and media plan.",
  ],
  integrationsTitle: "Integrations",
  integrationsSubtitle:
    "We are already integrated with the main platforms on the market and we also integrate with your own platform.",
  formTitle: "We connect brands and influencers to generate sales.",
  formSubtitle:
    "",
  formHeader: "I want to do the live commerce of my brand",
  formCompany: "Your company’s name",
  formCompanyError: "Type your company’s name.",
  formName: "My name",
  formNameError: "Type your name.",
  formEmail: "My e-mail address",
  formEmailError: "Type a valid email",
  formPhone: "Contact phone",
  formPhoneError: "Enter your contact phone number.",
  formEcommerceError: "Please select an option.",
  formQ1: "Do you use an e-commerce platform?",
  formQ1A1: "Yes",
  formQ1A2: "No",
  formPrivacy:
    "I authorize the use of my personal data above, for a period of 1 year, to receive contact from Alive, with the purpose of receiving a commercial contact. Meet",
  formPrivacyLink: "Privacy Policy",
  formButtonFirst: "Let's go!",
  formButton: "Start now",
  formSuccess: "Request sent successfully!",
  formSuccess1: "We will contact you via your e-mail address",
  formSuccess2: "or phone",
  formSuccess3: "If you prefer, send a message to contact@alive.haus",
  formError:
    "An unexpected error occurred while submitting your request. Please, try again in a few minutes. If you prefer, send a message to contato@alive.haus.",
  ourSolutions: "Our solutions",
  solution1: "Cases",
  solution2: "Technologies",
  company: "Company",
  aboutHuia: "About Huia",
  contact: "Contact",
  workWithUs: "Work with Us",
  support: "Support",
  privacyTerms: "Privacy Terms",
  group: "Our group",
  footerText: "We connect brands and influencers to generate sales.",
  footerTextBold: "Come talk to us!",
  onlineText: "LIVE",
  finishedText: "CLOSED",
  recordedText: "RECORDED",
  solution1Link: 'https://alive.haus/lives',
  solution2Link: '/',
  aboutHuiaLink: 'https://huia.haus/en/studio/',
  contactLink: 'https://web.whatsapp.com/send?phone=+5511989115782',
  workWithUsLink: 'https://stefanini.gupy.io/',
  supportLink: 'mailto:contato@alive.haus',
  privacyTermsLink: 'https://alive.haus/termos-de-uso-e-politicas-de-privacidade',
  phoneMask: ['+#-###-###-####', '+## (##) # ####-####'],
  other: 'Other',
  videoName: 'LIVE_HAUS_v2_en',
};

export { translationEN };
