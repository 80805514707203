<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="-3.61627147%" y1="49.5542372%" x2="104.821695%" y2="50.460138%" id="linearGradient-1">
            <stop stop-color="#EBC841" offset="0%"></stop>
            <stop stop-color="#F014A5" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-2.74571508%" y1="50.4294386%" x2="103.076551%" y2="49.5747743%" id="linearGradient-2">
            <stop stop-color="#231ED2" offset="0%"></stop>
            <stop stop-color="#F014A5" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="100%" y1="51.3888889%" x2="0%" y2="48.6111111%" id="linearGradient-3">
            <stop stop-color="#EBC841" offset="0%"></stop>
            <stop stop-color="#F014A5" offset="50.8779346%"></stop>
            <stop stop-color="#231ED2" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="icone24/menumobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-7" transform="translate(2.000000, 6.000000)" fill-rule="nonzero">
            <path d="M20,-0.5 C20.5522847,-0.5 21,-0.0522847498 21,0.5 C21,1.01283584 20.6139598,1.43550716 20.1166211,1.49327227 L20,1.5 L0,1.5 C-0.55228475,1.5 -1,1.05228475 -1,0.5 C-1,-0.0128358391 -0.61395981,-0.435507161 -0.116621125,-0.493272269 L0,-0.5 L20,-0.5 Z" id="Path-2" fill="url(#linearGradient-1)"></path>
            <path d="M20,5.5 C20.5522847,5.5 21,5.94771525 21,6.5 C21,7.01283584 20.6139598,7.43550716 20.1166211,7.49327227 L20,7.5 L0,7.5 C-0.55228475,7.5 -1,7.05228475 -1,6.5 C-1,5.98716416 -0.61395981,5.56449284 -0.116621125,5.50672773 L0,5.5 L20,5.5 Z" id="Path-2-Copy-2" fill="url(#linearGradient-2)"></path>
            <path d="M10,11.5 C10.5522847,11.5 11,11.9477153 11,12.5 C11,13.0128358 10.6139598,13.4355072 10.1166211,13.4932723 L10,13.5 L0,13.5 C-0.55228475,13.5 -1,13.0522847 -1,12.5 C-1,11.9871642 -0.61395981,11.5644928 -0.116621125,11.5067277 L0,11.5 L10,11.5 Z" id="Path-2-Copy-3" fill="url(#linearGradient-3)"></path>
        </g>
    </g>
  </svg>
</template>