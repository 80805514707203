<template>
  <button :type="type" @click="$emit('clicked')" class="Primary">
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    type: { type: String },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/buttons.scss";
</style>
