<template>
  <section class="_livehaus">
    <div>
      <img src="../../assets/images/live-haus-logo.webp" alt="Live Haus Logo" />
      <h3>{{ t.liveHausText }}</h3>
    </div>
    <div>
      <div class="_video">
        <img
          v-if="!loadVideo"
          src="../../assets/images/thumbnail-livehaus.webp"
          alt="Thumbnail vídeo Live Haus"
        />
        <button v-if="!loadVideo" @click="playVideo()">
          <PlayIcon />
        </button>
        <video
          :class="loadVideo ? 'show' : 'hide'"
          title="Alive Haus"
          preload="none"
          ref="hausVideo"
          controls
          :key="lang"
        >
          <!-- <source
            ref="srcVideo"
            :src="
              lang == 'PT'
                ? require('../../assets/videos/LIVE_HAUS_v2.mp4')
                : lang == 'EN'
                ? require('../../assets/videos/en-video.mp4')
                : require('../../assets/videos/LIVE_HAUS_v2.mp4')
            "
            type="video/mp4"
          /> -->
          <source
            ref="srcVideo"
            :src="`https://alive-production.s3.us-west-2.amazonaws.com/landingpage-videos/${t.videoName}.mp4`"
            type="video/mp4"
          />
        </video>
      </div>
      <ul v-if="!isMob()">
        <li v-for="n in 3" :key="n">
          <h4>{{ t.liveHausItensTitles[n - 1] }}</h4>
          <p>{{ t.liveHausItensTexts[n - 1] }}</p>
        </li>
      </ul>
      <Splide
        v-if="isMob()"
        :has-track="false"
        :options="{ pagination: false }"
        data-splide="{'type':'loop'}"
      >
        <SplideTrack>
          <SplideSlide v-for="n in 3" :key="n">
            <div>
              <h4>{{ t.liveHausItensTitles[n - 1] }}</h4>
              <p>{{ t.liveHausItensTexts[n - 1] }}</p>
            </div>
          </SplideSlide>
        </SplideTrack>
        <div class="splide__arrows">
          <button class="splide__arrow splide__arrow--prev">
            <SliderArrow />
          </button>
          <button class="splide__arrow splide__arrow--next">
            <SliderArrow />
          </button>
        </div>
      </Splide>
    </div>
  </section>
</template>

<script>
import { inject, computed } from "vue";
import PlayIcon from "@/assets/svg/PlayIcon.vue";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import SliderArrow from "@/assets/svg/SliderArrow.vue";

export default {
  name: "Haus",
  components: {
    PlayIcon,
    Splide,
    SplideSlide,
    SplideTrack,
    SliderArrow,
  },
  data: () => ({
    loadVideo: false,
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  }),
  setup() {
    const updateLang = inject("updateLang");
    const t = computed({
      get: () => inject("translation").value,
      set: updateLang,
    });
    const lang = computed({
      get: () => inject("lang").value,
    });

    return { t, lang };
  },
  methods: {
    playVideo() {
      this.loadVideo = true;
      this.$refs.hausVideo.play();
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
    isMob() {
      return this.width <= 1100;
    },
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
};
</script>

<style lang="scss">
@import "./assets/scss/haus.scss";
</style>
