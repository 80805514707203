<template>
  <section class="_hero">
    <div class="_central">
      <div class="_title-box">
        <h1>
          <span class="gradient">{{ t.mainTitle1 }}</span>
          <br />
          {{ t.mainTitle2 }}
        </h1>
        <p>{{ t.mainSubtitle }}</p>
        <!-- <div class="buttons">
          <Primary :label="t.ctaContact" @clicked="$emit('cta', 'formcta')" />
        </div> -->
        <Arrow @clicked="$emit('cta', 'formcta')"/>
      </div>

      <div class="_img-box">
        <video v-if="t.locale == 'pt'" autoplay muted loop id="myVideo">
          <source src="https://alive-production.s3.us-west-2.amazonaws.com/landingpage-videos/alive_herobanner_2022-PT.mp4" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
        <video v-else-if="t.locale == 'en'" autoplay muted loop id="myVideo">
          <source src="https://alive-production.s3.us-west-2.amazonaws.com/landingpage-videos/alive_herobanner_2022-EN.mp4" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
        <video v-else autoplay muted loop id="myVideo">
          <source src="https://alive-production.s3.us-west-2.amazonaws.com/landingpage-videos/alive_herobanner_2022-ES.mp4" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
      </div>
    </div>
  </section>
</template>

<script>
import Arrow from "@/components/buttons/Arrow";
import { inject, computed } from "vue";

export default {
  name: "Hero",
  components: {
    Arrow,
    // Primary,
    // Secondary,
  },
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });

    return { t };
  },
};
</script>

<style lang="scss">
@import "./assets/scss/hero.scss";
</style>
