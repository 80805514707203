<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12.5"
      cy="12.5"
      r="11.5"
      stroke="url(#paint0_linear_328_7406)"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 8.80425C10 8.01881 10.864 7.53997 11.53 7.95625L16.6432 11.152C17.2699 11.5437 17.2699 12.4563 16.6432 12.848L11.53 16.0438C10.8639 16.46 10 15.9812 10 15.1958V8.80425Z"
      fill="url(#paint1_linear_328_7406)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_328_7406"
        x1="2.5"
        y1="-4.11112"
        x2="26.919"
        y2="-2.31118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EBC841" />
        <stop offset="1" stop-color="#F014A5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_328_7406"
        x1="5.20835"
        y1="12.0015"
        x2="15.8128"
        y2="20.577"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EBC841" />
        <stop offset="1" stop-color="#F014A5" />
      </linearGradient>
    </defs>
  </svg>
</template>
