<template>
  <portal-target to="success" class="success">
    <div class="success__content">
      <div class="success__check"><check class="icon" /></div>
      <h2 class="success__title">{{ t.formSuccess }}</h2>
      <p class="success__text">
        {{ label }} <span class="success__data">{{ email }}</span> {{ label1 }}
        <span class="success__data">{{ telefone }}</span>
      </p>
      <p v-if="label2" class="success__text">
        {{ t.formSuccess3 }}
      </p>
      <div v-if="nuvem">
        <nuvem />
      </div>
      <Primary label="cadastrar novo e-mail" v-if="newEmail" />
    </div>
  </portal-target>
</template>

<script>
import { inject, computed } from "vue";
// botao
import Primary from "@/components/buttons/Primary";

// components
import Nuvem from "@/components/hashtags/Nuvem";

// svg
import Check from "@/assets/svg/Check";

export default {
  components: {
    Primary,
    Check,
    Nuvem,
  },
  props: {
    label: { type: String },
    label1: { type: String },
    label2: { type: Boolean },
    email: { type: String },
    telefone: { type: String },
    newEmail: { type: Boolean },
    nuvem: { type: Boolean },
  },
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });

    return { t };
  },
  methods: {
    close() {
      this.success = false;
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/sucesso.scss";
</style>
