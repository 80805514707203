<template>
  <div
    class="text-input"
    :class="`${error && regex && !regex.test(value) && !valid ? 'error' : ''} ${
      showLabel ? 'side-gradient' : ''
    }`"
  >
    <slot></slot>
    <p v-if="showLabel" class="text-placeholder">{{ placeholder }}</p>
    <input
      type="email"
      class="input"
      :placeholder="showLabel ? '' : placeholder"
      :value="emailValue"
      @input="$emit('update:emailValue', $event.target.value)"
      @focus="showLabel = true"
      @blur="showLabel = false"
    />
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      showLabel: false,
    };
  },
  props: {
    placeholder: { type: String },
    value: { type: String },
    emailValue: { type: String, default: "" },
    valid: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    regex: RegExp,
  },
};
</script>

<style lang="scss">
.input {
  padding-bottom: 5px;
  width: 100%;
  font-size: 16px;
  color: #2a2a2a;
  letter-spacing: 0;
  line-height: 24px;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(315.66deg, #f014a5 0%, #231ed2 100%);
  -moz-border-image: -moz-linear-gradient(315.66deg, #f014a5 0%, #231ed2 100%);
  -webkit-border-image: -webkit-linear-gradient(
    315.66deg,
    #f014a5 0%,
    #231ed2 100%
  );
  border-image-slice: 1;
  box-sizing: border-box !important;
  border-width: 0px 0px 1px 0px;
  &:focus {
    padding-left: 24px;
    outline: none;
  }
}

.text-input {
  position: relative;
  .text-placeholder {
    margin: 0;
    font-size: 12px;
    left: 24px;
    position: absolute;
    top: -17px;
  }
  &.error {
    .input {
      border-image: linear-gradient(315.66deg, red 0%, red 100%);
      -moz-border-image: -moz-linear-gradient(315.66deg, red 0%, red 100%);
      -webkit-border-image: -webkit-linear-gradient(
        315.66deg,
        red 0%,
        red 100%
      );
      border-image-slice: 1;
      &::-webkit-input-placeholder {
        color: red;
      }
    }
  }
}
</style>
