<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_58_9267)">
      <path
        d="M20.376 0H1.62403C1.19331 0 0.780231 0.171103 0.475667 0.475667C0.171103 0.780231 0 1.19331 0 1.62403V20.376C0 20.8067 0.171103 21.2198 0.475667 21.5243C0.780231 21.8289 1.19331 22 1.62403 22H20.376C20.8067 22 21.2198 21.8289 21.5243 21.5243C21.8289 21.2198 22 20.8067 22 20.376V1.62403C22 1.19331 21.8289 0.780231 21.5243 0.475667C21.2198 0.171103 20.8067 0 20.376 0ZM6.55722 18.7413H3.24958V8.23472H6.55722V18.7413ZM4.90111 6.77875C4.52592 6.77664 4.15976 6.66343 3.84884 6.45341C3.53792 6.2434 3.29619 5.94599 3.15414 5.59871C3.01209 5.25144 2.97609 4.86988 3.0507 4.50217C3.1253 4.13446 3.30715 3.79709 3.57331 3.53263C3.83947 3.26818 4.178 3.08849 4.54618 3.01626C4.91436 2.94402 5.29569 2.98246 5.64204 3.12674C5.98839 3.27101 6.28424 3.51465 6.49226 3.82691C6.70027 4.13917 6.81113 4.50605 6.81083 4.88125C6.81437 5.13245 6.7673 5.38179 6.67243 5.6144C6.57755 5.84702 6.43682 6.05816 6.25861 6.23523C6.0804 6.4123 5.86836 6.55168 5.63514 6.64505C5.40192 6.73843 5.15228 6.7839 4.90111 6.77875ZM18.7489 18.7504H15.4428V13.0106C15.4428 11.3178 14.7232 10.7953 13.7943 10.7953C12.8135 10.7953 11.851 11.5347 11.851 13.0533V18.7504H8.54333V8.24236H11.7242V9.69833H11.7669C12.0863 9.05208 13.2046 7.9475 14.9111 7.9475C16.7567 7.9475 18.7504 9.04292 18.7504 12.2513L18.7489 18.7504Z"
        fill="url(#paint0_linear_58_9267)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_58_9267"
        x1="-12.8531"
        y1="11.062"
        x2="11.0032"
        y2="35.177"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EBC841" />
        <stop offset="1" stop-color="#F014A5" />
      </linearGradient>
      <clipPath id="clip0_58_9267">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
