<template>
  <section class="_lives">
    <div class="d-flex _caixali">
      <div>
        <h2 class="_title">{{ t.clientsTitle }}</h2>
        <h3 class="_subtitle">{{ t.clientsText }}</h3>
      </div>
      <div class="_link-lives">
        <LivesIcon class="_archor" @click="redirect" />
        <p @click="redirect" v-if="!isMob()">{{ t.seeAllLives }}</p>
        <p @click="redirect" v-if="isMob()">{{ t.seeAllLivesMobile }}</p>
      </div>
    </div>
    <LivesList />
  </section>
</template>

<script>
import LivesList from "./list/LivesList.vue";
import LivesIcon from "@/assets/svg/LivesIcon.vue";
import { inject, computed } from "vue";

export default {
  name: "Lives",
  components: {
    LivesList,
    LivesIcon,
  },
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });

    return { t };
  },
  methods: {
    redirect() {
      window.open(`https://play.alive.haus/lives`);
    },
    isMob() {
      return window.innerWidth <= 900;
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/lives.scss";
</style>
