<template>
  <button class="dropdown">
    <div
      class="__container"
      :tabindex="tabindex"
      @blur="open = false"
      :class="[{ open: open }, bgcolor]"
    >
    <div class="__selected" :class="{ open: open }" @click="open = !open">
      <TranslationGlobe class="_translation_icon"/>
        <p>{{ lang }}</p>
        <SetaBaixo v-if="open == false" />
        <SetaAlto v-if="open == true" />
      </div>
      <div class="__items" :class="{ selectHide: !open }">
        <p
          v-for="(option, i) of options"
          :key="i"
          @click="
            selected = option;
            open = false;
            $emit('input', option);
            updateLang(selected);
          "
          :class="option == lang ? 'selected' : ''"
        >
          {{ option }}
        </p>
      </div>
    </div>
  </button>
</template>

<script>
import { inject, computed } from "vue";
import SetaBaixo from "@/assets/svg/SetaBaixo";
import SetaAlto from "@/assets/svg/SetaAlto";
import TranslationGlobe from "../../assets/svg/TranslationGlobe.vue";

export default {
  name: "Dropdown",
  components: {
    SetaBaixo,
    SetaAlto,
    TranslationGlobe
},
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    bgcolor: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },
  setup() {
    const updateLang = inject("updateLang");
    const lang = computed({
      get: () => inject("lang").value,
      set: updateLang,
    });
    const t = computed({
      get: () => inject("translation").value,
    });

    return { lang, updateLang, t };
  },
};
</script>

<style lang="scss">
@import "./assets/scss/dropdown.scss";
</style>
