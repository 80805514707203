<template>
  <div class="box">
    <ul class="box__list list-unstyled d-flex">
      <li
        class="card__live"
        v-for="(event, index) in filteredEvents"
        :key="index"
        @click="redirect(event)"
      >
        <div class="hover__cell">
          <img
            :src="require('../../../assets/images/live-hover.webp')"
            alt="Borda do celular com imagem da live"
          />
        </div>
        <div class="live__content storybook-hover2" :style="event.color">
          <!-- <img
            v-bind:src="event.banner"
            class="banner"
            title="banner-live"
            alt="banner-live"
          /> -->
          <div class="live__overlay">
            <div class="fixed__client">
              <span class="tag">
                <span class="avatar">
                  <img
                    v-bind:src="event.client.avatar"
                    title="logo"
                    alt="logo"
                  />
                </span>
                <!-- <span class="title">
                    {{ event.client.name }}
                  </span> -->
              </span>
            </div>
            <div>
              <PlayIcon />
              <div class="fixed__title">
                <h4>{{ event.title }}</h4>
              </div>
              <div class="fixed__date">
                <date-hour :startDate="event.startAt" />
              </div>
              <div v-if="event.state !== 'OFFLINE'" class="storybook-encerrada">
                <status-tag :eventState="event.state" />
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// svg
// import Player from '@/assets/svg/Player'

// components
import { inject, computed } from "vue";
import StatusTag from "@/components/status/StatusTag";
import { instanceEventAPI } from "@/api/main";
import DateHour from "@/components/lives/DateHour";
import PlayIcon from "@/assets/svg/PlayIcon";
export default {
  name: "LivesList",
  components: {
    StatusTag,
    DateHour,
    PlayIcon,
  },
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });

    return { t };
  },
  computed: {
    filteredEvents() {
      // busca pela lista de clientes pré selecionados
      const clientSet = new Set(
        this.events.map(e => e.client.tagName).filter(e => 
        e === 'desa-housebar' || e === 'ente' || e === 'hmstrangerthings' || e === 'marisa' || e === 'iorane' || e === 'youcom' ||
        e === 'dafiti' || e === 'tramontina' || e === 'riva-rj' || e === 'riva-sp' || e === 'riva-sp' || e === 'paola-santana')
      )
      // busca uma live de cada cliente
      const filtereds = []
      for (const client of clientSet) {
        const clientEvent = this.events.find(e => e.client.tagName === client)
        clientEvent.color = this.getRandomColor()
        filtereds.push(clientEvent)
      }
      filtereds.sort((a, b) => new Date(b.startAt) - new Date(a.startAt))
      // coloca as lives que estão na lingua do usuário antes das outras
      const inLanguage = filtereds.filter(e => e.client.locale.substring(0,2) == this.t.locale)
      return inLanguage.concat(filtereds.filter(e => e.client.locale.substring(0,2) != this.t.locale))
    }
  },
  data() {
    return {
      events: [],
    };
  },
  created() {
    instanceEventAPI
      .get()
      .then((resp) => {
        this.events = resp.data;
      })
      .catch((err) => {
        throw err;
      });
  },

  methods: {
    getRandomColor() {
      const randomNumber = Math.floor((Math.random()*200)+20);
      return {backgroundColor: `rgb(200,${randomNumber},20)`};
    },
    redirect(event) {
      const tagName = event.client.tagName;

      window.open(`https://play.alive.haus/${tagName}`);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/list.scss";
</style>
