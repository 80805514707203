<template>
    <button @click="$emit('clicked')" class="Arrow">
        <SetaBaixoGrande />
    </button>
</template>
  
<script>
import SetaBaixoGrande from "@/assets/svg/SetaBaixoGrande";

export default {
    name: "Arrow",
    components: {
        SetaBaixoGrande,
    },
};
</script>
  
<style lang="scss">
@import "./assets/scss/buttons.scss";
</style>
  