<template>
  <div
    class="text-input"
    :class="`${error && regex && !regex.test(value) && !valid ? 'error' : ''} ${
      showLabel ? 'side-gradient' : ''
    }`"
  >
    <slot></slot>
    <p v-if="showLabel" class="text-placeholder">{{ placeholder }}</p>
    <input
      type="phone"
      class="input"
      :regex="isValid.test(phoneValue)"
      :placeholder="showLabel ? '' : placeholder"
      v-mask="t.phoneMask"
      :value="phoneValue"
      @input="$emit('update:phoneValue', $event.target.value)"
      @focus="showLabel = true"
      @blur="showLabel = false"
    />
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { inject, computed } from "vue";

export default {
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });

    return { t };
  },
  props: {
    placeholder: { type: String },
    phoneValue: { type: String, default: "" },
    regex: RegExp,
    maskNumber: Boolean,
    valid: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLabel: false,
    };
  },
  computed: {
    isValid() {
      let exp = new RegExp("^([1-9]{2}) [9]{0,1}[6-9]{1}[0-9]{3}-[0-9]{4}$");
      return exp;
    },
  },
  directives: { mask },
};
</script>

<style lang="scss">
.input {
  padding-bottom: 5px;
  width: 100%;
  font-size: 16px;
  color: #2a2a2a;
  letter-spacing: 0;
  line-height: 24px;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(315.66deg, #f014a5 0%, #231ed2 100%);
  -moz-border-image: -moz-linear-gradient(315.66deg, #f014a5 0%, #231ed2 100%);
  -webkit-border-image: -webkit-linear-gradient(
    315.66deg,
    #f014a5 0%,
    #231ed2 100%
  );
  border-image-slice: 1;
  box-sizing: border-box !important;
  border-width: 0px 0px 1px 0px;
  &:focus {
    outline: none;
  }
}

.text-input {
  position: relative;
  .text-placeholder {
    margin: 0;
    font-size: 12px;
    left: 24px;
    position: absolute;
    top: -17px;
  }
  &.error {
    .input {
      border-image: linear-gradient(315.66deg, red 0%, red 100%);
      -moz-border-image: -moz-linear-gradient(315.66deg, red 0%, red 100%);
      -webkit-border-image: -webkit-linear-gradient(
        315.66deg,
        red 0%,
        red 100%
      );
      border-image-slice: 1;
      &::-webkit-input-placeholder {
        color: red;
      }
    }
  }
}
</style>
