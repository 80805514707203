<template>
  <portal-target to="error" class="error">
    <div class="error__content">
      <div class="error__err"> <close class="icon" /> </div>
      <h2 class="error__title">ops!</h2>
      <p class="error__text">{{ label }}</p>
      <Primary label="cadastrar novamente" @click="backtoForm" :key="componentKey"/>
    </div>
  </portal-target>
</template>

<script>
// botao
import Primary from '@/components/buttons/Primary'

// svg 
import Close from '@/assets/svg/Close'

export default {
  components: {
    Primary,
    Close
  },
  props: {
    label: { type: String }
  },
  methods:{
    backtoForm () {
      this.$router.go(0);
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/error.scss';
</style>