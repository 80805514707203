const translationPT = {
  locale: "pt",
  ctaContact: "Comece agora",
  mainTitle1: "Transforme",
  mainTitle2: "a sua forma de vender online com a Alive.",
  mainSubtitle:
    "Conecte-se com seus clientes e faça vendas ao vivo, sem precisar de um aplicativo!",
  demo: "agende uma demo",
  featuresTitle: "A melhor experiência",
  featuresSubtitles: [
    "Sem aplicativo",
    "Interações em tempo real",
    "Botões dinâmicos",
    "Processo integrado",
    "Personalização",
    "Suporte",
  ],
  featuresText: [
    "basta acessar um link pelo celular ou computador.",
    "para conversar com o vendedor ou outros clientes.",
    "que mostram os produtos e levam direto ao carrinho de compras.",
    "ao seu e-commerce: basta um clique para finalizar a compra.",
    "da plataforma para ficar com a identidade da sua marca.",
    "e acompanhamento técnico durante todo o processo.",
  ],
  clientsTitle: "Clientes",
  clientsText:
    "Marcas dos mais diferentes segmentos e tamanhos utilizam o Alive.",
  seeAllLives: "Visualizar todas as lives",
  seeAllLivesMobile: "todas as lives",
  liveHausText:
    "O Live Haus é um conjunto de serviços que oferece tudo que você precisa em um só lugar.",
  liveHausItensTitles: ["Tecnologia", "Produção", "Ativação"],
  liveHausItensTexts: [
    "Plataforma Alive para lives interativas, com integração direta ao e-commerce.",
    "Planejamento do evento, roteiro, seleção de influenciador e interações ao vivo.",
    "Planejamento de ativação, com peças de divulgação e plano de mídia.",
  ],
  integrationsTitle: "Integrações",
  integrationsSubtitle:
    "Já estamos integrados com as principais plataformas do mercado e também integramos com a sua plataforma proprietária.",
  formTitle: "Conectamos marcas e influenciadores para gerar vendas.",
  formSubtitle:
    "",
  formHeader: "Quer saber qual o produto mais se encaixa nos seus Planos?",
  formCompany: "Nome da minha empresa",
  formCompanyError: "Coloque o nome da sua empresa.",
  formName: "Meu nome",
  formNameError: "Coloque o seu nome.",
  formEmail: "Meu email",
  formEmailError: "Informe um email valido",
  formPhone: "Telefone para contato",
  formPhoneError: "Coloque o seu telefone de contato.",
  formEcommerceError: "Selecione uma opção.",
  formQ1: "Vocês utilizam plataforma de e-commerce?",
  formQ1A1: "Sim",
  formQ1A2: "Não",
  formPrivacy:
    "Autorizo o uso do meu dado pessoal acima, pelo período de 1 ano, para receber contato da Alive, com a finalidade de receber um contato comercial. Conhecer a ",
  formPrivacyLink: "Política de Privacidade",
  formButtonFirst: "Vamos lá!",
  formButton: "Comece agora",
  formSuccess: "Solicitação enviada com sucesso!",
  formSuccess1: "Nós entraremos em contato com você pelo seu e-mail",
  formSuccess2: "ou telefone",
  formSuccess3: "Se preferir, envie um e-mail para contato@alive.haus.",
  formError:
    "Um erro inesperado aconteceu durante o envio da sua solicitação. Tente novamente em alguns instantes. Se preferir, envie um e-mail para contato@alive.haus.",
  ourSolutions: "Nossas soluções",
  solution1: "Cases",
  solution2: "Tecnologias",
  company: "A empresa",
  aboutHuia: "Sobre a Huia",
  contact: "Contato",
  workWithUs: "Trabalhe conosco",
  support: "Ouvidoria",
  privacyTerms: "Termos de Privacidade",
  group: "O grupo",
  footerText: "Conectamos marcas e influenciadores para gerar vendas.",
  footerTextBold: "Vem falar com a gente!",
  onlineText: "AO VIVO",
  finishedText: "ENCERRADA",
  recordedText: "GRAVADA",
  solution1Link: 'https://alive.haus/lives',
  solution2Link: '/',
  aboutHuiaLink: 'https://huia.haus/pt/estudio/',
  contactLink: 'https://web.whatsapp.com/send?phone=+5511989115782',
  workWithUsLink: 'https://stefanini.gupy.io/',
  supportLink: 'mailto:contato@alive.haus',
  privacyTermsLink: 'https://alive.haus/termos-de-uso-e-politicas-de-privacidade',
  phoneMask: ['(##) ####-####', '(##) #####-####'],
  other: 'Outro',
  videoName: 'LIVE_HAUS_v2',
};

export { translationPT };
