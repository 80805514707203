<template>
    <div class="card" v-if="!success && !error">
        <h4 class="card__title">{{ t.formHeader }}</h4>
        <form class="card__form" @submit.prevent="post">
            <div v-if="presentation" class="form-info">

                <div class="form-group">
                    <email-input v-model:emailValue="client.email" :error="showErrors" :regex="emailReg" type="email"
                        :placeholder="t.formEmail" />
                    <small class="errorMessage" v-if="emailError">
                        {{ t.formEmailError }}
                    </small>
                </div>

                <div class="d-flex justify-content-around">
                    <Tertiary :label="t.formButtonFirst" :class="valid1 ? 'btn' : 'btn disabled'" @clicked="go" @isMessage="isMessage"/>
                </div>
            </div>

            <div v-else>

                <div class="form-group">
                    <text-input v-model:textValue="client.enterprise" :error="showErrors" :regex="reg" type="text"
                        :placeholder="t.formCompany" />
                    <small class="errorMessage" v-if="enterpriseError">
                        {{ t.formCompanyError }}
                    </small>
                </div>

                <div class="form-group">
                    <text-input v-model:textValue="client.contact" :error="showErrors" :regex="reg" type="text"
                        :placeholder="t.formName" />
                    <small class="errorMessage" v-if="contactError">
                        {{ t.formNameError }}
                    </small>
                </div>

                <div class="form-group">
                    <phone-input v-model:phoneValue="client.phone" :error="showErrors" :regex="phoneReg" type="text"
                        :placeholder="t.formPhone" :maskNumber="true" />
                    <small class="errorMessage" v-if="phoneError">
                        {{ t.formPhoneError }}
                    </small>
                </div>

                <div class="form-group" :class="`${showErrors && !client.ecommerce ? 'error' : ''}`">
                    <radio-input :error="showErrors" v-model:radioValue="client.ecommerce" :label="t.formQ1"
                        :options="[t.formQ1A1, t.formQ1A2]" group="hasEcommerce" />
                    <small class="errorMessage" v-if="ecommerceError">
                        {{ t.formEcommerceError }}
                    </small>
                </div>

                <div class="form-group" :class="`${showErrors && !client.ecommerceOption ? 'error' : ''}`"
                    v-if="hasEcommerce">
                    <radio-input :error="showErrors" v-model:radioValue="client.ecommerceOption" :label="t.formQ1"
                        :options="['Vtex', 'Anota Ai', 'Dooca', 'Magento', 'Vnda', 'Tray', 'Shopify', 'Linx', t.other]"
                        group="ecommercesType" />
                    <small class="errorMessage" v-if="ecommerceOptionError">
                        {{ t.formEcommerceError }}
                    </small>
                </div>

                <div :class="`${showErrors && !client.terms ? 'error' : ''}`">
                    <checkbox-input v-model:checkboxValue="client.terms" :label="t.formPrivacy" />
                </div>

                <div class="d-flex justify-content-around">
                    <Tertiary :label="t.formButton" :class="valid2 ? 'btn' : 'btn disabled'" @click="post" />
                </div>
            </div>
        </form>
    </div>

    <success label="Nós entraremos em contato com você pelo seu e-mail" :email="client.email" label1="ou telefone"
        :telefone="client.phone" :label2="true" :newEmail="false" :nuvem="false" v-if="success" />
    <error v-show="error" :description="errorMessage"
        label="Um erro inesperado aconteceu durante o envio da sua solicitação. Tente novamente em alguns instantes. Se preferir, envie um e-mail para contato@alive.haus."
        v-if="error" />
</template>
  
<script>
import { inject, computed } from "vue";

// métodos auxiliares
import { instanceUsersAPI } from '@/api/main'

// botao
import Tertiary from "../../buttons/Tertiary.vue";

// inputs
import PhoneInput from '@/components/inputs/Phone'
import TextInput from '@/components/inputs/Text'
import EmailInput from "@/components/inputs/Email";
import CheckboxInput from '@/components/inputs/Checkbox'
import RadioInput from "@/components/inputs/Radio";

// portais
import Success from '@/components/portals/Success'
import Error from '@/components/portals/Error'


export default {
    emits: ['isMessage'],
    components: {
        PhoneInput,
        TextInput,
        EmailInput,
        CheckboxInput,
        RadioInput,
        Success,
        Error,
        Tertiary
    },
    setup() {
        const t = computed({
            get: () => inject("translation").value,
        });

        return { t };
    },
    computed: {
        hasEcommerce() {
            return this.client.ecommerce === this.t.formQ1A1
        },
        valid1() {
            return !this.emailError && this.client.email
        },
        valid2() {
            return this.client.enterprise && this.client.contact &&
                this.client.terms && this.client.ecommerce &&
                !this.phoneError && this.client.phone &&
                !this.ecommerceOptionError && (this.client.ecommerceOption || !this.hasEcommerce)
        }
    },
    data() {
        return {
            presentation: true,
            message: false,
            fase1: 'progress',
            fase2: 'disabled',
            client: {
                enterprise: "",
                contact: "",
                phone: "",
                email: "",
                ecommerce: "",
                ecommerceOption: "",
                terms: false,
            },
            success: false,
            error: false,
            errorMessage: "falha na tentativa de efetuar solicitação.",
            showErrors: false,
            reg: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/,
            emailReg: /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i,
            phoneReg: /^(\(\d\d\) \d\d\d\d\d-\d\d\d\d)?$/,
            enterpriseError: false,
            contactError: false,
            phoneError: false,
            emailError: false,
            ecommerceError: false,
            ecommerceOptionError: false,
            maxCount: 500,
            zeroCount: 0,
            hasError: false,
        };
    },
    methods: {
        post() {
            this.enterpriseError = !this.client.enterprise;
            this.contactError = !this.client.contact;
            this.phoneError = !this.client.phone;
            this.ecommerceError = !this.client.ecommerce;
            if (this.hasEcommerce) {
                this.ecommerceOptionError = !this.client.ecommerceOption;
            }

            this.showErrors = this.enterpriseError || this.contactError || this.phoneError || this.emailError || this.ecommerceError || this.ecommerceOptionError
            if (this.showErrors || this.hasError) {
                return;
            }

            const user = {
                fullName: this.client.contact,
                enterpriseName: this.client.enterprise,
                email: this.client.email,
                phone: this.client.phone,
                ecommerce: this.client.ecommerce,
                acceptPrivacyPolity: this.client.terms,
                aboutProject: this.hasEcommerce ? this.client.ecommerceOption : this.client.ecommerce,
            };
            instanceUsersAPI
                .create(user)
                .then(() => {
                    this.error = false;
                    this.success = true;
                })
                .catch((err) => {
                    this.error = true;
                    this.success = false;
                    this.errorMessage = err.response
                        ? err.response.data.message
                        : err.message;
                });
        },

        //Função para contabilizar quantidade de caracteres
        countdown() {
            this.zeroCount = this.client.project.length;
            this.hasError = this.zeroCount > this.maxCount;
        },
        go() {
            this.presentation = false
            this.message = true
            this.fase1 = 'concluded'
            this.fase2 = 'progress'
        },
        back1() {
            this.presentation = true
            this.message = false
            this.fase1 = 'progress'
            this.fase2 = 'disabled'
        },
        isMessage() {
            this.$emit("isMessage", this.message)
        }
    },
    watch: {
        'client.email' (val) {
            this.emailError = !val.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
        'client.enterprise' (val) {
            this.enterpriseError = !val;
        },
        'client.contact' (val) {
            this.contactError = !val;
        },
        'client.phone' (val) {
            this.phoneError = val.length < 10;
        },
        'client.ecommerce' (val) {
            this.ecommerceError = !val;
        },
        'client.ecommerceOption' (val) {
            if (this.hasEcommerce) {
                this.ecommerceOptionError = !val;
            }
        },
    }
};
</script>
  
<style lang="scss">
@import "../assets/scss/form.scss";

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 42px;
}

.errorMessage {
    color: red;
}

.project {
    margin-top: -43px;
}

.projectInput {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.count {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .messageCount {
        margin-bottom: 40px;
    }
}

@include mediabreak-min('tablet') {
.form-info {
    display: flex;
    width: 100%;
    align-items: baseline;

    .form-group {
        width: 60%;
        margin-bottom: 0;
    }

    .d-flex.justify-content-around {
        width: 40%;
    }

    button {
        width: 90%;
        word-wrap: normal;
        padding: 0;
        height: 45px;
    }
}}
</style>
  