<template>
  <div class="countdown">
    <div class="_detalhe"> {{ countdown.days }} ÀS {{ countdown.hours }}:{{ countdown.minutes }} </div>
  </div>
</template>

<script>
export default {
  props: {
    startDate: String
  },
  mounted () {
    this.countdown = this.calculate()
  },
  data () {
    return {
      countdown: {
        days: '00',
        hours: '00',
        minutes: '00'
      },
      count: null
    }
  },
  methods: {
    calculate () {
      const date = new Date(this.startDate)
      const options = { day: '2-digit', month: '2-digit', year: '2-digit' }
      const eventDate = date.toLocaleDateString('pt-br', options)
      const eventHour = date.getHours().toString()
      const eventMinutes = date.getMinutes().toString()
      return {
        days: eventDate.split('/').join('.'),
        hours: eventHour.padStart(2, '0'),
        minutes: eventMinutes.padStart(2, '0')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.countdown {
  display: flex;
  align-items: center;
  ._detalhe {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    padding: 7px 0 7px 0;
  }
}
</style>
