<template>
  <div class="radio-container" :id="group">
    <p>{{ label }}</p>
    <div class="_inputs">
      <div v-for="option in options" :key="option">
        <div class="radio">
          <slot></slot>

          <label class="_label">
            <input
              class="_radio"
              type="radio"
              :value="radioValue == option ? true : false"
              :name="group"
              v-on:input="$emit('update:radioValue', option)"
            />
          </label>
        </div>
        <p class="_text">
          {{ option }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, computed } from "vue";

export default {
  model: {
    prop: "checked",
    event: "change",
  },
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });

    return { t };
  },
  props: {
    radioValue: String,
    label: { type: String },
    options: { required: true, type: Array },
    group: { type: String },
  },
  data() {
    return {
      checked: false,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/main";

.error {
  .radio-container {
    ._label {
      border: 2px solid red;
      border-radius: 20px;
      width: 22px;
      height: 22px;
      background: transparent;
    }
  }
}

.radio-container {
  display: flex;
  flex-direction: column;
  ._inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
      width: 20%;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:not(:last-of-type) {
        margin-right: 50px;
      }
      ._text {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        margin: 0;
      }
    }
  }
}
.radio {
  display: flex;
  ._label {
    width: 26px;
    height: 26px;
    padding: 2px;
    margin-right: 8px;
    margin-bottom: 0;
    position: relative;
    border: 2px solid transparent;
    background: url("../../assets/images/radio-input.webp");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ._radio {
      outline: none;
      appearance: none;
      position: absolute;
      -moz-appearance: none;
      cursor: pointer;
      &:after,
      &:checked {
        content: "";
        width: 14px;
        height: 14px;
        outline: none;
        margin: 0;
        border-radius: 0px;
        background-image: $linear-1;
        border-radius: 50px;
        position: initial;
      }
      @supports (-webkit-touch-callout: none) {
        left: 3px;
      }
    }
  }
}
</style>
