<template>
  <section class="_features">
    <h2>{{ t.featuresTitle }}</h2>
    <div>
      <img class="_desktop-img" src="../../assets/images/features-bg.webp" alt="Linhas curvas de fundo na sessão"
        loading="lazy" />
      <img class="_mobile-img" src="../../assets/images/features-bg-mobile.webp" alt="Linhas curvas de fundo na sessão"
        loading="lazy" />
      <ul v-if="!isMob()">
        <li v-for="n in 6" :key="n">
          <img :src="require('../../assets/images/feature' + n + '.webp')" />
          <h3>{{ t.featuresSubtitles[n - 1] }}</h3>
          <p>{{ t.featuresText[n - 1] }}</p>
        </li>
      </ul>
      <Splide v-if="isMob()" :has-track="false" :options="{ pagination: false }" data-splide='{"type":"loop"}'>
        <SplideTrack>
          <SplideSlide v-for="n in 6" :key="n">
            <img :src="require('../../assets/images/feature' + n + '.webp')" />
            <h3>{{ t.featuresSubtitles[n - 1] }}</h3>
            <p>{{ t.featuresText[n - 1] }}</p>
          </SplideSlide>
        </SplideTrack>
        <div class="splide__arrows">
          <button class="splide__arrow splide__arrow--prev">
            <SliderArrow />
          </button>
          <button class="splide__arrow splide__arrow--next">
            <SliderArrow />
          </button>
        </div>
      </Splide>
      <div class="buttons">
        <Primary :label="t.ctaContact" @clicked="$emit('cta', 'formcta')" />
        <!-- <Secondary :label="t.demo" /> -->
      </div>
    </div>
  </section>
</template>

<script>
import { inject, computed } from "vue";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import SliderArrow from "@/assets/svg/SliderArrow.vue";
import Primary from "@/components/buttons/Primary";

export default {
  name: "Features",
  components: {
    Splide,
    SplideSlide,
    SplideTrack,
    SliderArrow,
    Primary,
  },
  data: () => ({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  }),
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });

    return { t };
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
    isMob() {
      return this.width <= 900;
    },
    log: function (e) {
      console.log(e.currentTarget);
      console.log(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/scss/features.scss";
</style>
