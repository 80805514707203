/** Classe responsável por coordenar ações da rota /contatos/ */
export default class UsersAPI {
  /**
         * Recebe uma instância axios para consultar o endpoint /contatos/.
         * @param {Axios} instance - Instância axios criada através do comando axios.create({...})
        */
  constructor (instance) {
    this.instance = instance
    this.base = '/contatos/'
  }

  /**
     * Método responsável por listar todos os usuários.
     * @return {Promise} Promise com a resposta da requisição.
    */
  create (data) {
    const call = this.instance.post('contatos/para-empresas/', data)
    return call
  }
}
