import axios from 'axios'
import UsersAPI from './user.api'
import EventAPI from './event.api'

// Cria uma instância da api Axios cuja url base é http://localhost:5000
const aliveAPI = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.VUE_APP_ACESS_TOKEN}`,
  }
})

const eventsAPI = axios.create({
  baseURL: process.env.VUE_APP_API_EVENT_URL,
  headers: {
    'Content-Type': 'application/json',
    authorization: `Bearer ${process.env.VUE_APP_ACESS_TOKEN}`
  }
})

const instanceUsersAPI = new UsersAPI(aliveAPI)
const instanceEventAPI = new EventAPI(eventsAPI)

// Exporta todas as instâncias de APIs cadastradas e utilizadas pelo projeto
export {
  instanceUsersAPI,
  instanceEventAPI
}