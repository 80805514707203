<template>
  <div class="landing" ref="landing">
    <section>
      <Header @cta="goTo"/>
      <Hero @cta="goTo"/>
      <div class="formcta" ref="formcta">
        <FormBasicHome color="basic" :modalBas="false" :enterprise="true"/>
      </div>
      <Features @cta="goTo"/>
      <Lives />
      <Haus />
      <Integration @cta="goTo"/>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Hero from "@/components/hero/Hero";
import Features from "@/components/features/Features";
import Lives from "@/components/lives/Lives";
import Haus from "@/components/haus/Haus";
import Integration from "@/components/integration/Integration";
import Footer from "@/components/footer/Footer";
import { provide, reactive, computed } from "vue";
import { translationPT } from "@/translations/pt";
import { translationEN } from "@/translations/en";
import { translationES } from "@/translations/es";
import FormBasicHome from "../components/contact/ContactShort.vue";

export default {
  name: "Home",
  components: {
    Header,
    Hero,
    Lives,
    Haus,
    Features,
    Integration,
    Footer,
    FormBasicHome
},
  setup() {
    const lang = reactive({
      current: navigator.language.includes("pt")
        ? "PT"
        : navigator.language.includes("en")
        ? "EN"
        : navigator.language.includes("es")
        ? "ES"
        : "PT",
    });
    const translation = reactive({
      texts: navigator.language.includes("pt")
        ? translationPT
        : navigator.language.includes("en")
        ? translationEN
        : navigator.language.includes("es")
        ? translationES
        : translationPT,
    });
    const updateLang = (newLang) => {
      lang.current = newLang;

      if (newLang == "PT") {
        translation.texts = translationPT;
      } else if (newLang == "EN") {
        translation.texts = translationEN;
      } else {
        translation.texts = translationES;
      }
    };

    console.log();
    provide(
      "lang",
      computed(() => lang.current)
    );
    provide(
      "translation",
      computed(() => translation.texts)
    );
    provide("updateLang", updateLang);
  },
  methods: {
    goTo (refName) {
      const element = this.$refs[refName]
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
