<template>
  <svg
    width="18"
    height="30"
    viewBox="0 0 18 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2L16 15L2 28"
      stroke="url(#paint0_linear_211_7492)"
      stroke-width="2.5"
      stroke-miterlimit="16"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_211_7492"
        x1="-25.5"
        y1="-0.5"
        x2="-26.1139"
        y2="13.099"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EBC841" />
        <stop offset="1" stop-color="#F014A5" />
      </linearGradient>
    </defs>
  </svg>
</template>
