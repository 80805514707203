export default class EventAPI {
    /**
           * Recebe uma instância axios para consultar o endpoint /landing/eventos.
           * @param {Axios} instance - Instância axios criada através do comando axios.create({...})
          */
    constructor (instance) {
      this.instance = instance
      this.base = 'event'
    }

    /**
       * Método responsável por obter todos os eventos.
       * @return {Promise} Promise com a resposta da requisição.
      */

    get () {
      const call = this.instance.get(`${this.base}`, { params: { published: 1 } })
      return call
    }
  }
