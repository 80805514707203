<template>
  <section class="_integration-platforms">
    <div class="_description">
      <h2 class="_title">{{ t.integrationsTitle }}</h2>
      <h3 class="_subtitle">
        {{ t.integrationsSubtitle }}
      </h3>
    </div>
    <div class="flex-wrapper">
      <div>
        <img src="./assets/images/vtex.png" alt="Vtex" />
      </div>
      <div>
        <img src="./assets/images/magento.png" alt="Magento" />
      </div>
      <div>
        <img src="./assets/images/linx.png" alt="Linx" />
      </div>
      <div>
        <img src="./assets/images/dooca.png" alt="Dooca" />
      </div>
      <div>
        <img src="./assets/images/vnda.png" alt="Vnda" />
      </div>
      <div>
        <img src="./assets/images/moovin.png" alt="Moovin" />
      </div>
      <div>
        <img src="./assets/images/shopify.png" alt="Shopify" />
      </div>
      <div>
        <img src="./assets/images/whatsapp.png" alt="Whatsapp" />
      </div>
    </div>
    <div class="buttons">
        <Primary :label="t.ctaContact" @clicked="$emit('cta', 'formcta')" />
        <!-- <Secondary :label="t.demo" /> -->
      </div>
  </section>
</template>

<script>
import { inject, computed } from "vue";
import Primary from "@/components/buttons/Primary";

export default {
  name: "Integration",
  components: {
    Primary,
    // Secondary,
  },
  methods: {
    isDesktop() {
      return window.innerWidth >= 1000;
    },
  },
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });

    return { t };
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/scss/integration.scss";
</style>
