<template>
  <footer class="footer">
    <div class="logo">
      <Logo class="icon" />
    </div>
    <div class="footer__content">
      <div class="footer__column">
        <h3>{{ t.ourSolutions }}</h3>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              :href="t.solution1Link"
            >{{ t.solution1 }}</a>
          </li>
          <li>
            <a 
              :href="t.solution2Link" 
              target="_blank" 
              rel="noreferrer noopener"
            >{{ t.solution2 }}</a>
          </li>
        </ul>
        <div class="social">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.instagram.com/alive.haus/"
            ><InstaIcon
          /></a>
          <!-- <a href="/"><FacebookIcon /></a> -->
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.youtube.com/channel/UCg9_ViCEVXMkM0--eIm_NPA"
            ><YoutubeIcon
          /></a>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.linkedin.com/company/alive-haus/"
            ><LinkedinIcon
          /></a>
        </div>
      </div>
      <div class="footer__column">
        <h3>{{ t.company }}</h3>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              :href="t.aboutHuiaLink"
              >{{ t.aboutHuia }}</a
            >
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              :href="t.contactLink"
            >
              {{ t.contact }}
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              :href="t.workWithUsLink"
            >
              {{ t.workWithUs }}
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              :href="t.supportLink"
              >{{ t.support }}</a
            >
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              :href="t.privacyTermsLink"
              >{{ t.privacyTerms }}
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__column">
        <h3>{{ t.group }}</h3>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://stefanini.com/"
              >Stefanini</a
            >
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://gauge.digital/"
              >Gauge</a
            >
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.brookehaus.com/"
              >Brooke</a
            >
          </li>
          <li>
            <a target="_blank" rel="noreferrer noopener" href="https://w3.haus/"
              >W3</a
            >
          </li>
        </ul>
      </div>
      <div class="footer__column">
        <p class="text">
          {{ t.footerText }}
        </p>
        <p class="text">
          <strong>{{ t.footerTextBold }}</strong>
        </p>
        <a :href="`mailto:${email}@alive.haus`" class="link"
          >{{ email }}@alive.haus</a
        >
        <a href="" class="link" ref="phoneLink"></a>
          <img src="../../assets/images/gauge-logo.png" width="144" height="55"/>
      </div>
    </div>
    <a
      target="_blank"
      rel="noreferrer noopener"
      href="https://web.whatsapp.com/send?phone=+5511989115782"
      class="footer__whatsapp"
    >
      <WhatsappIcon />
    </a>
  </footer>
</template>

<script>
import WhatsappIcon from "@/assets/svg/WhatsappIcon";
import InstaIcon from "@/assets/svg/InstaIcon";
// import FacebookIcon from "@/assets/svg/FacebookIcon";
import LinkedinIcon from "@/assets/svg/LinkedinIcon";
import YoutubeIcon from "@/assets/svg/YoutubeIcon";
import Logo from "@/assets/svg/LogoAliveHaus";
import { inject, computed } from "vue";

export default {
  name: "Footer",
  components: {
    Logo,
    WhatsappIcon,
    InstaIcon,
    // FacebookIcon,
    LinkedinIcon,
    YoutubeIcon,
  },
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });
    return { t };
  },
  mounted() {
    this.$refs.phoneLink.innerHTML = "+55 11 99189-2973";
    this.$refs.phoneLink.href = "tel:+551199189-2973";
  },
  computed: {
    email: {
      get() {
        const base = new URL(window.location.href);
        const find = new URLSearchParams(base.search.slice(1));
        const params = find.get("utm_source");
        const params1 = base.search.slice(1).split("=");
        let email = "";
        switch (params || params1[0]) {
          case "linkedin":
            email = "atendimento";
            break;
          case "fb":
          case "ig":
            email = "live";
            break;
          case "google-search":
          case "google":
            email = "alive";
            break;
          default:
            email = "contato";
        }
        return email;
      },
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/footer.scss";
</style>
