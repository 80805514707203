const translationES = {
  locale: "es",
  ctaContact: "Agenda una demo",
  mainTitle1: "Transforma",
  mainTitle2: "la forma de vender en línea con Alive",
  mainSubtitle:
    "Conéctate con tus clientes y haz las ventas en vivo, ¡sin necesitar de una aplicación!",
  demo: "conoce más, agenda una demo",
  featuresTitle: "La mejor experiencia",
  featuresSubtitles: [
    "Sin aplicación",
    "Interacciones en tiempo real",
    "Botones dinámicos",
    "Proceso integrador",
    "Personalización",
    "Apoyo en todo el proceso",
  ],
  featuresText: [
    "simplemente acceda a un enlace desde tu teléfono celular o computadora.",
    "para chatear con el vendedor u otros clientes.",
    "que muestran los productos y los llevan directo al carrito de la compra.",
    "directo a tu e-commerce: solo un click para completar la compra.",
    "desde la plataforma para mantener su identidad de marca.",
    "y soporte técnico durante todo el proceso.",
  ],
  clientsTitle: "Clientes",
  clientsText: "Conoce a algunas de nuestros clientes que ya utilizan Alive.",
  seeAllLives: "Ver todos los lives",
  seeAllLivesMobile: "todos los lives",
  liveHausText:
    "Live Haus es un conjunto de servicios que ofrece todo lo que necesitas en un solo lugar.",
  liveHausItensTitles: ["Tecnología", "Producción", "Activación"],
  liveHausItensTexts: [
    "Plataforma Alive para lives interactivos, con integración directa a la tienda online.",
    "Planificación de eventos, guión, selección de influencers e interacciones en vivo.",
    "Planificación de activación, con piezas publicitarias y plan de medios.",
  ],
  integrationsTitle: "Integraciones",
  integrationsSubtitle:
    "Estamos integrados con las principales plataformas del mercado y nos podemos integrar con tu plataforma propia.",
  formTitle: "Conectamos marcas e influencers para generar ventas.",
  formSubtitle:
    "",
  formHeader: "Quiero hacer el comercio en vivo de mi marca",
  formCompany: "Nombre de mi empresa",
  formCompanyError: "Ingrese el nombre de su empresa.",
  formName: "Mi nombre",
  formNameError: "Ingrese su nombre.",
  formEmail: "Mi correo electrónico",
  formEmailError: "Ingrese un correo electrónico válido",
  formPhone: "Teléfono de contacto",
  formPhoneError: "Pon tu teléfono de contacto.",
  formEcommerceError: "Seleccione una opción.",
  formQ1: "¿Utiliza una plataforma de comercio electrónico?",
  formQ1A1: "Sí",
  formQ1A2: "No",
  formPrivacy:
    "Autorizo ​​el uso de mis datos personales anteriores, por un período de 1 año, para recibir contacto de Alive, con el fin de recibir un contacto comercial. Conocer a ",
  formPrivacyLink: "política de Privacidad",
  formButtonFirst: "¡Vámonos!",
  formButton: "Empieza ahora",
  formSuccess: "¡Solicitud enviada con éxito!",
  formSuccess1:
    "Nos pondremos en contacto contigo a través de tu correo electrónico",
  formSuccess2: "o teléfono",
  formSuccess3:
    "Si lo prefiere, envíe un correo electrónico a contato@alive.haus.",
  formError:
    "ocurrió un error inesperado al enviar su solicitud. Vuelva a intentarlo en unos momentos. Si lo prefiere, envíe un correo electrónico a contato@alive.haus.",
  ourSolutions: "Nuestras soluciones",
  solution1: "Casos",
  solution2: "Tecnologías",
  company: "La empresa",
  aboutHuia: "Sobre Huia",
  contact: "Contacto",
  workWithUs: "Trabaja con nosotros",
  support: "Soporte",
  privacyTerms: "Términos de Privacidad",
  group: "El grupo",
  footerTeEt: "Conectamos marcas e influenciadores para generar ventas.",
  footerTextBold: "¡Ven y conversa con nosotros!",
  onlineText: "EN VIVO",
  finishedText: "CERRADO",
  recordedText: "GRABADO",
  solution1Link: 'https://alive.haus/lives',
  solution2Link: '/',
  aboutHuiaLink: 'https://huia.haus/es/el-estudio/',
  contactLink: 'https://web.whatsapp.com/send?phone=+56999745265',
  workWithUsLink: 'https://stefanini.gupy.io/',
  supportLink: 'mailto:contato@alive.haus',
  privacyTermsLink: 'https://alive.haus/termos-de-uso-e-politicas-de-privacidade',
  phoneMask: ['+## # ####-####', '+## # ####-####'],
  other: 'Otro',
  videoName: 'LIVE_HAUS_v2_es',
};

export { translationES };
