<template>
  <div class="nuvem">
    <a class="tags" @click="e => e.target.classList.toggle('active')"><span>#decoracao</span></a>
    <a class="tags" @click="e => e.target.classList.toggle('active')"><span>#diy</span></a>
    <a class="tags" @click="e => e.target.classList.toggle('active')"><span>#educação</span></a>
    <a class="tags" @click="e => e.target.classList.toggle('active')"><span>#esportes</span></a>
    <a class="tags" @click="e => e.target.classList.toggle('active')"><span>#finanças</span></a>
    <a class="tags" @click="e => e.target.classList.toggle('active')"><span>#games</span></a>
    <a class="tags" @click="e => e.target.classList.toggle('active')"><span>#gastronomia</span></a>
    <a class="tags" @click="e => e.target.classList.toggle('active')"><span>#kids</span></a>
    <a class="tags" @click="e => e.target.classList.toggle('active')"><span>#moda</span></a>
    <a class="tags" @click="e => e.target.classList.toggle('active')"><span>#skincare</span></a>
    <a class="tags" @click="e => e.target.classList.toggle('active')"><span>#tecnologia</span></a>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss">
@import './assets/scss/nuvem.scss'
</style>