<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 24 24" width= "18">
    <defs>
      <linearGradient x1="83.3443673%" y1="103.166016%" x2="16.8682979%" y2="-2.65625%" id="linearGradient-2">
          <stop stop-color="#231ED2" offset="0%"></stop>
          <stop stop-color="#F014A5" offset="100%"></stop>
      </linearGradient>
    </defs>
    <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" stroke="url(#linearGradient-2)" stroke-width="1" fill="url(#linearGradient-2)" />
  </svg>
</template>