<template>
  <header class="_header" :class="{ scrolling: scrollPosition > 50 }">
    <a href="/home"> <logo class="_logo" /> </a>
    <div>
      <Dropdown
        class="_dropdown-header"
        :options="options"
        :bgcolor="{ white: scrollPosition > 50 }"
      />
      <Primary :label="t.ctaContact" @clicked="$emit('cta', 'formcta')" />
      <div class="_icon">
        <Menu @click="menu = !menu" />
      </div>
    </div>
    <div class="menu-card" v-if="menu">
      <Dropdown :options="options" :bgcolor="white" />
      <button class="_closemenu" @click="menu = !menu">
        <span class="_closegradient">X</span>
      </button>
    </div>
  </header>
</template>

<script>
import Logo from "@/assets/svg/LogoAliveHaus";
import Menu from "@/assets/svg/Menu";
import Primary from "@/components/buttons/Primary";
import Dropdown from "@/components/dropdown/Dropdown";
import { inject, computed } from "vue";

export default {
  name: "Header",
  components: {
    Logo,
    Primary,
    Dropdown,
    Menu
},
  data() {
    return {
      options: ["PT", "EN", "ES"],
      scrollPosition: null,
      menu: false,
      white: "white",
    };
  },
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });

    return { t };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style lang="scss">
@import "./assets/scss/header.scss";
</style>
