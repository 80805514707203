<template>
  <div class="grid">
    <div class="checkbox">
      <slot></slot>

      <label class="_label">
        <input
          class="_checkbox"
          type="checkbox"
          value="checkboxValue"
          v-on:input="$emit('update:checkboxValue', $event.target.checked)"
        />
      </label>
    </div>
    <div>
      <p class="legal">
        {{ label }} <a href="">{{ t.formPrivacyLink }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import { inject, computed } from "vue";

export default {
  model: {
    prop: "checked",
    event: "change",
  },
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });

    return { t };
  },
  props: {
    checkboxValue: Boolean,
    label: { type: String },
  },
  data() {
    return {
      checked: false,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/main";

$checkbox-dimension: 25px;

.grid {
  display: grid;
  grid-template-columns: $checkbox-dimension + 15px auto;
}

.error {
  .checkbox {
    ._label {
      border: 2px solid red;
    }
  }
}

.checkbox {
  display: flex;
  cursor: pointer;
  font-size: xx-small;
  ._label {
    width: 23px;
    height: 23px;
    padding: 2px;
    margin-right: 14px;
    position: relative;
    border: 2px solid transparent;
    -o-border-image: $linear-1;
    border-image: $linear-1;
    border-image-slice: 1;
    box-sizing: border-box !important;
    cursor: pointer;
    ._checkbox {
      outline: none;
      appearance: none;
      position: absolute;
      -moz-appearance: none;
      &:after,
      &:checked {
        content: "";
        width: 100%;
        height: 100%;
        outline: none;
        margin: 0;
        border-radius: 0px;
        background-image: $linear-1;
        border-radius: 2px;
        position: initial;
      }
      @supports (-webkit-touch-callout: none) {
        left: 3px;
      }
    }
  }
}

.legal {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0 14px 26px 0;
  a {
    color: #231ed2;
    text-decoration: underline;
  }
}
</style>
