<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="icone16/anteriores"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M1.56811594,2 C1.25436715,2 1,2.25436715 1,2.56811594 L1,13.9304345 C1,14.2441833 1.25436715,14.4985504 1.56811594,14.4985504 L14.0666665,14.4985504 C14.3804153,14.4985504 14.6347824,14.2441833 14.6347824,13.9304345 L14.6347824,4.37341127 C14.6347824,4.05966248 14.3804153,3.80532667 14.0666665,3.80529533 L7.61449258,3.80529533 L6.68115925,2.56811594 C6.68112791,2.25436715 6.4267921,2 6.11304331,2 L1.56811594,2 Z"
        id="Path"
        fill="#8F8F8F"
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
</template>
