<template>
  <button type="button" @click="$emit('clicked');$emit('isMessage')" class="Tertiary">
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/buttons.scss";
</style>
