<template>
  <div class="storybook-archived">
    <div class="archived">
      <div v-if="isOnline()">
        <span class="archived__icon"><AoVivo /></span>
        <span class="archived__title">{{ t.onlineText }}</span>
      </div>
      <div v-if="isEnded()">
        <span class="archived__title">{{ t.finishedText }}</span>
      </div>
      <div v-if="isRecorded()">
        <span class="archived__icon"><Anteriores /></span>
        <span class="archived__title">{{ t.recordedText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Anteriores from "@/assets/svg/Anteriores";
import AoVivo from "@/assets/svg/AoVivo";
import { inject, computed } from "vue";

export default {
  components: {
    Anteriores,
    AoVivo,
  },
  methods: {
    isOnline() {
      return this.eventState === "ONLINE";
    },
    isEnded() {
      return this.eventState === "ENDED";
    },
    isRecorded() {
      return this.eventState === "RECORDED";
    },
  },
  setup() {
    const t = computed({
      get: () => inject("translation").value,
    });

    return { t };
  },
  props: {
    eventState: String,
  },
};
</script>

<style lang="scss">
@import "./assets/scss/archived.scss";
</style>
