<template>
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.5" cx="33" cy="33" r="33" fill="white" />
    <circle cx="33" cy="33" r="28.4483" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.3103 23.5639C27.3103 22.7505 28.2297 22.2774 28.8916 22.7502L42.1022 32.1863C42.6605 32.5851 42.6605 33.415 42.1022 33.8138L28.8916 43.2499C28.2297 43.7227 27.3103 43.2495 27.3103 42.4362V23.5639Z"
      fill="url(#paint0_linear_98_8591)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_98_8591"
        x1="17.7683"
        y1="33.0034"
        x2="41.0426"
        y2="49.472"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EBC841" />
        <stop offset="1" stop-color="#F014A5" />
      </linearGradient>
    </defs>
  </svg>
</template>
