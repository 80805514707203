import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home";
// import PolicyAndTerms from "@/views/PolicyAndTerms";
// import Cookies from "@/views/Cookies";
// import Enterprise from "@/views/Enterprise";

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/politicas-de-privacidade-e-termos-de-uso",
    name: "policy-and-terms",
    // component: PolicyAndTerms,
    beforeEnter() {
      window.location.replace('https://play.alive.haus/termos-de-uso-e-politicas-de-privacidade', '_blank')
    }
  },
  {
    path: "/gerenciamento-de-cookies",
    name: "cookies",
    // component: Cookies,
    beforeEnter() {
      window.location.replace('https://play.alive.haus/gerenciamento-de-cookies', '_blank')
    }
  },
  { // se o path for correspondente a algum evento, redireciona para o alive app
    path: "/:clientTag/live/:liveTitle/:liveId",
    name: "Play Alive",
    beforeEnter(to) {
      window.location.replace(`https://play.alive.haus${to.fullPath}`)
    }
  },
  { // se o path for correspondente a algum live center de cliente redireciona para o alive app
    path: "/:clientTag/",
    name: "Client live center",
    beforeEnter(to) {
      window.location.replace(`https://play.alive.haus${to.fullPath}`)
    }
  },
  {
    path: "",
    redirect: "/home",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
