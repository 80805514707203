<template>
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Landing" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Página-de-estilos" transform="translate(-467.000000, -5860.000000)">
            <rect fill="#F8F8F8" x="0" y="0" width="1440" height="9914"></rect>
            <g id="Group" transform="translate(58.000000, 5664.000000)" stroke="#2A2A2A" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                <g id="icone16/anteriores-copy-2" transform="translate(409.000000, 196.000000)">
                    <polyline id="Path-2-Copy" points="3 11 8 6 13 11"></polyline>
                </g>
            </g>
        </g>
    </g>
  </svg>
</template>