<template>
    <div :class="[(color === 'basic' ? 'news' : 'enterprise'), (message ? 'spacing' : '')]" el="contact-form">
      <div>
        <h2 class="news__title align-self-center align-items-start">
          {{ t.formTitle }}
        </h2>
        <h3 class="news__subtitle align-self-center align-items-start">
          {{ t.formSubtitle }}
        </h3>
      </div>
  
      <div v-if="enterprise">
        <FormContactShort v-if="!modalEnt" @isMessage="isMessage"/>
      </div>
    </div>
  </template>
  
  <script>

  import FormContactShort from "./forms/FormContactShort";
  
  
  import { inject, computed } from "vue";
  
  export default {
    components: {
      FormContactShort
    },
    props: {
      label: { type: String },
      color: { type: String },
      button: { type: String },
      basic: { type: Boolean },
      enterprise: { type: Boolean },
      modalBas: { type: Boolean },
      modalEnt: { type: Boolean },
    },
    setup() {
      const t = computed({
        get: () => inject("translation").value,
      });
  
      return { t };
    },
    methods: {
      isMessage(value) {
            this.message = value
      }
  },
    data: () => ({
      showBas: false,
      showEnt: false,
      message: false
    }),
  };
  </script>
  
  <style lang="scss">
  @import "./assets/scss/contact.scss";
  </style>
  