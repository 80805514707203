<template>
  <svg width="28px" height="27px" viewBox="0 0 28 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="[Schutz]-Abertura" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="Live_Login-sucesso" transform="translate(-33.000000, -141.000000)" stroke="#FFFFFF" stroke-width="2">
            <g id="Group-17" transform="translate(13.000000, 133.000000)">
                <polyline id="Path-2" points="21.5172414 28.2413793 26 33.235499 30.4827586 28.2413793 52 2.90019166"></polyline>
            </g>
        </g>
    </g>
  </svg>
</template>