<template>
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_46_8284)">
      <path
        d="M5.14441 48.5756L8.51475 36.3235C6.43546 32.7377 5.34201 28.6696 5.34332 24.5032C5.34878 11.4667 16.0055 0.860596 29.0994 0.860596C35.4544 0.863453 41.419 3.32579 45.9036 7.79484C50.3892 12.2638 52.8579 18.2041 52.8553 24.5216C52.8499 37.5585 42.1925 48.1655 29.1003 48.1655C29.0994 48.1655 29.101 48.1655 29.1003 48.1655H29.0897C25.114 48.1642 21.2075 47.1706 17.738 45.288L5.14441 48.5756Z"
        fill="url(#paint0_linear_46_8284)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.9531 14.2568C22.4925 13.2373 22.0076 13.2169 21.5694 13.1991C21.211 13.1838 20.8008 13.1847 20.3911 13.1847C19.9812 13.1847 19.315 13.3381 18.7516 13.9507C18.1879 14.5632 16.5993 16.0441 16.5993 19.0558C16.5993 22.0679 18.8028 24.9783 19.11 25.387C19.4175 25.7952 23.3637 32.1733 29.6133 34.627C34.8073 36.6662 35.8643 36.2606 36.9917 36.1586C38.1191 36.0565 40.6295 34.6781 41.1419 33.2485C41.6544 31.8193 41.6544 30.5942 41.5006 30.3382C41.3469 30.0831 40.9369 29.93 40.322 29.6239C39.707 29.3178 36.6842 27.8366 36.1205 27.6325C35.5568 27.4284 35.1469 27.3264 34.7369 27.9393C34.327 28.5515 33.1493 29.93 32.7905 30.3382C32.4318 30.7472 32.0731 30.7983 31.4582 30.4921C30.8432 30.1851 28.8629 29.5393 26.5137 27.4539C24.6858 25.8315 23.4517 23.8277 23.093 23.2148C22.7343 22.6026 23.0546 22.271 23.363 21.9658C23.6391 21.6915 23.978 21.2512 24.2854 20.8937C24.5922 20.5362 24.6947 20.2811 24.8997 19.873C25.1047 19.4642 25.0022 19.1067 24.8485 18.8006C24.6947 18.4945 23.4998 15.4672 22.9531 14.2568Z"
        fill="white"
      />
      <path
        d="M46.5104 7.18728C41.8641 2.5582 35.6858 0.00753659 29.1034 0.00463867C15.5391 0.00463867 4.50061 10.9911 4.49515 24.4951C4.49355 28.8117 5.6264 33.0253 7.77964 36.7395L4.28857 49.4307L17.3337 46.0251C20.9279 47.9766 24.9746 49.005 29.0928 49.0065H29.1033H29.1034C42.6654 49.0065 53.7056 38.0182 53.7113 24.5148C53.7136 17.9703 51.1561 11.8167 46.5104 7.18728ZM29.1034 44.8701H29.0954C25.4247 44.8682 21.825 43.8871 18.6853 42.0322L17.938 41.5912L10.1968 43.6119L12.2636 36.1002L11.7768 35.3301C9.72921 32.0888 8.64827 28.3429 8.64987 24.4967C8.65433 13.2727 17.8291 4.1414 29.1111 4.1414C34.5745 4.14365 39.7093 6.26382 43.5712 10.1114C47.4329 13.9589 49.5579 19.0736 49.5563 24.5133C49.5515 35.7376 40.3764 44.8701 29.1034 44.8701Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_46_8284"
        x="0"
        y="0"
        width="58"
        height="58"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_46_8284"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_46_8284"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_46_8284"
        x1="28.9999"
        y1="48.5756"
        x2="28.9999"
        y2="0.860675"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#20B038" />
        <stop offset="1" stop-color="#60D66A" />
      </linearGradient>
    </defs>
  </svg>
</template>
